<template>
  <div class="app-card">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="8">
            <a-form-model-item label="商家名称" prop="name">
              <a-input v-model="searchForm.name" />
            </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <!-- <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a> -->
        </a-col>
      </a-row>
    </a-form>
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" type="primary" @click="addSupplierClick" v-if="$root.$data.privateState.action.includes('/Supplier/Create') || $root.$data.privateState.role === 'SuperAdmin'">添加商家</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
        :scroll="{ x: 1200 }"
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #manDaoRegisterStatus="manDaoRegisterStatus, data">
          <a-tag v-if="!manDaoRegisterStatus" @click="toManDaoRegister(data)">未注册</a-tag>
          <a-tag v-if="manDaoRegisterStatus === 'BaseInfo'" color="cyan" @click="toManDaoUploadFile(data)">待上传文件</a-tag>
          <a-tag v-else-if="manDaoRegisterStatus === 'UploadFiles'" color="blue" @click="toManDaoResendRegisterMail(data)">重发鉴权邮件</a-tag>
          <a-tag v-if="manDaoRegisterStatus === 'Done'" color="green">已完成开户</a-tag>
        </template>
        <template #shopThumbnail="shopThumbnail">
          <a-avatar :src="`${$appBaseUrl}${shopThumbnail}`" shape="square" :size="128" icon="user" />
        </template>
        <template #action="data">
          <a-row>
            <a-col :span="12"><a-button type="link" @click="toDetail(data)" block>详情</a-button></a-col>
            <a-col :span="12"><a-button type="link" @click="toTransferLogs(data)" block>转款记录</a-button></a-col>
            <a-col :span="12"><a-button type="link" @click="toEdit(data)" block>编辑</a-button></a-col>
            <a-col :span="12"><a-popconfirm
            title="是否确认删除该条?"
            @confirm="toDelete(data)"
            okText="是"
            cancelText="否"
          >
            <a-button type="link" style="color:#ff4d4f;" block>删除</a-button>
          </a-popconfirm></a-col>
          </a-row>
          
          
          
        </template>
      </a-table>
    </div>

    <a-drawer
      width="640"
      :title="shopDetail.name"
      placement="right"
      :closable="false"
      :visible="shopDetailShow"
      @close="shopDetailShow = false"
    >
      <a-carousel autoplay v-if="shopDetail.shopImages && shopDetail.shopImages.length > 0">
        <div v-for="it of shopDetail.shopImages" :key="it">
          <h3><img :src="`${$appBaseUrl}${it}`" alt=""></h3>
        </div>
      </a-carousel>
      <a-descriptions :column="1" title="">
        <a-descriptions-item label="商家名称">{{shopDetail.name}}</a-descriptions-item>
        <a-descriptions-item label="商家简介">{{shopDetail.shopProfile}}</a-descriptions-item>
        <a-descriptions-item label="积分转换比例">{{shopDetail.scoreRate}}%</a-descriptions-item>
        <a-descriptions-item label="佣金比例">{{shopDetail.commissionRate}}%</a-descriptions-item>
        <a-descriptions-item label="商家介绍">
          <div v-html="shopDetail.content"></div>
        </a-descriptions-item>
      </a-descriptions>
      <a-divider>商品</a-divider>
      <a-row :gutter="[8,8]">
        <a-col :span="12" v-for="item in shopDetail.commodity" :key="item.key">
          <a-card :title="item.name" style="width: 100%">
            <div>
              {{item.profile}}
            </div>
            <div slot="actions">
              价格：{{item.price}}
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-drawer>
    
    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>

    <!-- 宝付基础信息注册 -->
    <a-modal
      width="90%"
      :maskClosable="false"
      :destroyOnClose="true"
      :visible="manDaoRegisterFormShow"
      @cancel="manDaoRegisterFormShow = false"
      @ok="manDaoRegisterFormPost"
      title="开户基础信息填写"
    >
      <a-form-model
        ref="manDaoRegisterFormRef"
        :model="manDaoRegisterForm"
        :rules="manDaoRegisterFormRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row :gutter="6">
          <a-col :span="8">
            <a-form-model-item label="联系邮箱" prop="Email">
              <a-input v-model="manDaoRegisterForm.Email" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="企业名称" prop="MerchantName">
              <a-input v-model="manDaoRegisterForm.MerchantName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="企业地址" prop="MerchantAddress">
              <a-input v-model="manDaoRegisterForm.MerchantAddress" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="社会信用代码" prop="RegNo">
              <a-input v-model="manDaoRegisterForm.RegNo" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="注册资本(单位：元)" prop="RegCapital">
              <a-input v-model="manDaoRegisterForm.RegCapital" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="法人姓名" prop="CorporateName">
              <a-input v-model="manDaoRegisterForm.CorporateName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="法人身份证号" prop="CorporateIdCard">
              <a-input v-model="manDaoRegisterForm.CorporateIdCard" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="联系人手机号" prop="ContactMobile">
              <a-input v-model="manDaoRegisterForm.ContactMobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户名称" prop="CardName">
              <a-input v-model="manDaoRegisterForm.CardName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行" prop="BankName">
              <a-input v-model="manDaoRegisterForm.BankName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行所在省" prop="ProvinceName">
              <a-input v-model="manDaoRegisterForm.ProvinceName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行所在市" prop="CityName">
              <a-input v-model="manDaoRegisterForm.CityName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行支行名称" prop="BranchName">
              <a-input v-model="manDaoRegisterForm.BranchName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="企业对公账号" prop="CardNo">
              <a-input v-model="manDaoRegisterForm.CardNo" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="经营范围" prop="BusinessScope">
              <a-input v-model="manDaoRegisterForm.BusinessScope" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :maskClosable="false"
      :destroyOnClose="true"
      :visible="manDaoUploadFileFormShow"
      @cancel="manDaoUploadFileFormShow = false"
      @ok="manDaoUploadFileFormPost"
      title="上传企业文件"
    >
      <a-row>
        <a-upload
          list-type="picture-card"
          :default-file-list="manDaoLicense"
          :remove="handleManDaoLicenseRemove"
          :before-upload="beforeManDaoLicenseUpload"
        >
          <div v-if="manDaoLicense.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
      </a-row>
      <a-row>
        <a-upload
          list-type="picture-card"
          :default-file-list="manDaoCorporateIdCardFront"
          :remove="handleManDaoCorporateIdCardFrontRemove"
          :before-upload="beforeManDaoCorporateIdCardFrontUpload"
        >
          <div v-if="manDaoCorporateIdCardFront.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
      </a-row>
      <a-row>
        <a-upload
          list-type="picture-card"
          :default-file-list="manDaoCorporateIdCardBack"
          :remove="handleManDaoCorporateIdCardBackRemove"
          :before-upload="beforeManDaoCorporateIdCardBackUpload"
        >
          <div v-if="manDaoCorporateIdCardBack.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
      </a-row>
    </a-modal>
    <!-- 转款日志 -->
    <a-modal
      width="60%"
      v-model="toTransferLogsShow"
      title="转款日志"
      @ok="toTransferLogsShow = false"
    >
      <a-input-group compact>
        <a-range-picker v-model="logSearchTime" />
        <a-button type="primary" icon="search" @click="searchTransferLogs">查询</a-button>
      </a-input-group>
      <a-table
        :columns="logColumns"
        :rowKey="record => record.requestNo"
        :dataSource="logData"
        :pagination="logPagination"
        :loading="logLoading"
        @change="handleLogTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #amount="amount">
          {{ parseFloat(amount) / 100 }}
        </template>
        <template #tradeDate="tradeDate">
          {{moment(tradeDate,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:ss:mm")}}
        </template>
        <template #tradeType="tradeType">
          <a-tag color="purple" v-if="tradeType === 'RECHARGE'">充值</a-tag>
          <a-tag color="cyan" v-else-if="tradeType === 'WITHDRAW'">提现</a-tag>
          <a-tag color="green" v-else-if="tradeType === 'SPLIT_IN'">入账</a-tag>
          <a-tag color="red" v-else-if="tradeType === 'REFUND'">退款</a-tag>
        </template>
        <template #status="status">
          <a-tag color="green" v-if="status === 'SUCCESS'">成功</a-tag>
          <a-tag color="red" v-else-if="status === 'FAILED'">失败</a-tag>
          <a-tag color="orange" v-else-if="status === 'PROCESS'">处理中</a-tag>
          <a-tag color="cyan" v-else-if="status === 'FINISH'">完成</a-tag>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import { Supplier } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"name":""}'

const columns = [
  {
    title: '供应商名称',
    dataIndex: 'name',
    width: 150,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '简介',
    dataIndex: 'shopProfile',
  },
  {
    title: '积分转换比例',
    dataIndex: 'scoreRate',
    width: 150,
  },
  {
    title: '佣金比例',
    dataIndex: 'commissionRate',
    width: 150,
  },
  {
    title: '开户状态',
    dataIndex: 'manDaoRegisterStatus',
    scopedSlots: { customRender: 'manDaoRegisterStatus' },
    width: 150,
  },
  {
    title: '添加时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 160,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]

export default {
  name: 'SupplierTableList',
  mixins: [aliveVM, mixinTableList],
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.fetch();
    });
  },
  async mounted () {
    const baseColumns = []
    this.$set(this, 'columns', JSON.parse(JSON.stringify(columns)))
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.fetch()
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      moment,
      tableListApi: `${Supplier}/TableList`,
      searchParams: {},
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      columns: [],
      baseColumns: [],
      // other
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      shopDetail: {
        name: '',
        content: '',
        addressPosition: '北京'
      },
      shopDetailShow: false,
      nowEditUuid: '',
      // 开户相关
      // 基础注册信息
      manDaoRegisterFormShow: false,
      manDaoRegisterForm: {
        Email: '',
        InControl: '1',
        InBenefit: '1',
        Proposer: '1',
        MerchantName: '',
        MerchantAddress: '',
        RegNo: '',
        RegCapital: '',
        CorporateName: '',
        CorporateIdCard: '',
        ContactMobile: '',
        CardName: '',
        BankName: '',
        ProvinceName: '',
        CityName: '',
        BranchName: '',
        CardNo: '',
        BusinessScope: '',
      },
      manDaoRegisterFormRules: {
        Email: [{ required: true, message: "请输入", trigger: "blur" }],
        MerchantName: [{ required: true, message: "请输入", trigger: "blur" }],
        MerchantAddress: [{ required: true, message: "请输入", trigger: "blur" }],
        RegNo: [{ required: true, message: "请输入", trigger: "blur" }],
        RegCapital: [{ required: true, message: "请输入", trigger: "blur" }],
        CorporateName: [{ required: true, message: "请输入", trigger: "blur" }],
        CorporateIdCard: [{ required: true, message: "请输入", trigger: "blur" }],
        ContactMobile: [{ required: true, message: "请输入", trigger: "blur" }],
        CardName: [{ required: true, message: "请输入", trigger: "blur" }],
        BankName: [{ required: true, message: "请输入", trigger: "blur" }],
        ProvinceName: [{ required: true, message: "请输入", trigger: "blur" }],
        CityName: [{ required: true, message: "请输入", trigger: "blur" }],
        BranchName: [{ required: true, message: "请输入", trigger: "blur" }],
        CardNo: [{ required: true, message: "请输入", trigger: "blur" }],
        BusinessScope: [{ required: true, message: "请输入", trigger: "blur" }],  
      },
      // 上传文件
      manDaoUploadFileFormShow: false,
      manDaoLicense: [],
      manDaoCorporateIdCardFront: [],
      manDaoCorporateIdCardBack: [],
      // 转款记录
      toTransferLogsShow: false,
      logColumns: [
        {
          title: '转款时间',
          dataIndex: 'tradeDate',
          scopedSlots: { customRender: 'tradeDate' },
        },
        {
          title: '类型',
          dataIndex: 'tradeType',
          scopedSlots: { customRender: 'tradeType' },
        },
        {
          title: '金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '说明',
          dataIndex: 'tradeDesc'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        }
      ],
      nowLogUuid: '',
      logData: [],
      logPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        filters: null,
        sorter: null
      },
      logLoading: false,
      logSearchTime: null
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', {})
      
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', {})
      
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      let searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'startAt' || k === 'endAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      if (licia.isEmpty(searchParams)) {
        searchParams = {}
      }
      
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async addSupplierClick() {
      this.$router.push({ name: 'SupplierAddAndEdit' }).catch(err => err)
    },
    async getDetail (uuid) {
      const apiRes = await this.$axios.get(`${Supplier}/Detail?uuid=${uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      return apiRes.data;
    },
    async toEdit (data) {
      this.$router.push({ name: 'SupplierAddAndEdit', params: {uuid: data.uuid} }).catch(err => err)
    },
    async toDelete (data) {
      this.loading = true
      const res = await this.$axios.delete(`${Supplier}/Delete?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.loading = false
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.fetch()
    },
    // 详情
    async toDetail (data) {
      const detail = await this.getDetail(data.uuid)
      if (detail === null) return
      this.$set(this, 'shopDetail', detail)
      this.shopDetailShow = true
    },
    // 开户相关
    toManDaoRegister(it) {
      this.nowEditUuid = it.uuid
      this.$set(this, 'manDaoRegisterForm', {
        Email: '',
        InControl: '1',
        InBenefit: '1',
        Proposer: '1',
        MerchantName: '',
        MerchantAddress: '',
        RegNo: '',
        RegCapital: '',
        CorporateName: '',
        CorporateIdCard: '',
        ContactMobile: '',
        CardName: '',
        BankName: '',
        ProvinceName: '',
        CityName: '',
        BranchName: '',
        CardNo: '',
        BusinessScope: '',
      })
      this.manDaoRegisterFormShow = true;
    },
    async manDaoRegisterFormPost() {
      this.$refs.manDaoRegisterFormRef.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容')
          return false
        }
        const postData = JSON.parse(JSON.stringify(this.manDaoRegisterForm))
        this.modalLoadingMessage = '正在处理数据'
        this.modalLoading = true
        const res = await this.$axios.post(`${Supplier}/ManDaoRegister?uuid=${this.nowEditUuid}`, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.modalLoading = false
          this.$message.error(res.message)
          return
        }
        this.modalLoading = false
        this.manDaoRegisterFormShow = false
        this.$message.success("信息注册成功，请提交企业资料")
        this.fetch()
      })
    },
    // 宝付上传注册文件
    toManDaoUploadFile(it) {
      this.nowEditUuid = it.uuid
      this.manDaoUploadFileFormShow = true
    },
    handleManDaoLicenseRemove(file) {
      const index = this.manDaoLicense.indexOf(file);
      const newManDaoLicense = this.manDaoLicense.slice();
      newManDaoLicense.splice(index, 1);
      this.manDaoLicense = newManDaoLicense;
    },
    beforeManDaoLicenseUpload(file) {
      this.manDaoLicense = [file]
      return false;
    },
    handleManDaoCorporateIdCardFrontRemove(file) {
      const index = this.manDaoCorporateIdCardFront.indexOf(file);
      const newManDaoCorporateIdCardFront = this.manDaoCorporateIdCardFront.slice();
      newManDaoCorporateIdCardFront.splice(index, 1);
      this.manDaoCorporateIdCardFront = newManDaoCorporateIdCardFront;
    },
    beforeManDaoCorporateIdCardFrontUpload(file) {
      this.manDaoCorporateIdCardFront = [file]
      return false;
    },
    handleManDaoCorporateIdCardBackRemove(file) {
      const index = this.manDaoCorporateIdCardBack.indexOf(file);
      const newManDaoCorporateIdCardBack = this.manDaoCorporateIdCardBack.slice();
      newManDaoCorporateIdCardBack.splice(index, 1);
      this.manDaoCorporateIdCardBack = newManDaoCorporateIdCardBack;
    },
    beforeManDaoCorporateIdCardBackUpload(file) {
      this.manDaoCorporateIdCardBack = [file]
      return false;
    },
    async manDaoUploadFileFormPost()
    {
      if (this.manDaoLicense.length < 1 || this.manDaoCorporateIdCardFront.length < 1 || this.manDaoCorporateIdCardBack.length < 1)
      {
        this.$message.error("请上传相关证件图片");
        return
      }
      const formData = new FormData();
      formData.append('license', this.manDaoLicense[0], this.manDaoLicense[0].name)
      formData.append('corporateIdCardFront', this.manDaoCorporateIdCardFront[0], this.manDaoCorporateIdCardFront[0].name)
      formData.append('corporateIdCardBack', this.manDaoCorporateIdCardBack[0], this.manDaoCorporateIdCardBack[0].name)
      this.modalLoadingMessage = '正在处理数据'
      this.modalLoading = true
      const res = await this.$axios
        .post(`${Supplier}/ManDaoUploadRegisterFile?uuid=${this.nowEditUuid}`, formData)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.modalLoading = false
        this.$message.error(res.message)
        return
      }
      this.modalLoading = false
      this.$message.success("提交企业资料成功，请注意查收邮件，进行小额打款验证")
      this.manDaoUploadFileFormShow = false
      this.fetch()
    },
    async toManDaoResendRegisterMail(it) {
      this.modalLoadingMessage = '正在处理数据'
      this.modalLoading = true
      const res = await this.$axios
        .get(`${Supplier}/ManDaoSendBindCardEmail?uuid=${it.uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.modalLoading = false
        this.$message.error(res.message)
        return
      }
      this.modalLoading = false
      this.$message.success("邮件发送成功，请注意查收邮件")
      
    },
    toTransferLogs(it) {
      this.nowLogUuid = it.uuid
      this.$set(this, 'logSearchTime', null)
      this.$set(this, 'logData', [])
      this.$set(this, 'logPagination', {
        current: 1,
        pageSize: 10,
        total: 0,
        filters: null,
        sorter: null
      })
      this.toTransferLogsShow = true
      // this.getTransferLogs()
    },
    searchTransferLogs() {
      if (!this.logSearchTime) return
      this.getTransferLogs()
    },
    handleLogTableChange (pagination) {
      const pager = { ...this.logPagination }
      pager.current = pagination.current
      this.logPagination = pager
      this.getLogsPage()
    },
    async getTransferLogs() {
      if (!this.logSearchTime || this.logSearchTime.length < 2) {
        this.$message.error('请选择查询时间')
        return
      }
      const startDate = this.logSearchTime[0].format("YYYYMMDD") + '000000'
      const endDate = this.logSearchTime[1].format("YYYYMMDD") + '235959'
      this.logLoading = true
      const listRes = await this.$axios.post(`${Supplier}/ManDaoGetBalDetail?uuid=${this.nowLogUuid}`, {
        pageSize: this.logPagination.pageSize,
        currentPage: this.logPagination.current,
        startDate,
        endDate,
      }).catch(e => {
        this.logLoading = false
        return { status: false, message: e.message.indexOf('401') > -1 ? '登录超时' : e.message }
      })
      this.logLoading = false
      if (!listRes.status) {
        this.$notification.error({ message: '获取数据错误', description: listRes.message })
        return
      }
      if (!listRes.data.success) {
          this.$notification.error({ message: '获取数据错误', description: listRes.data.errorMsg })
					return
				}
      this.$set(this, 'logData', listRes.data.result.results)
      this.$set(this.logPagination, 'total', listRes.data.result.totalSize)
    }
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 350px;
}
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>